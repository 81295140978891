import axios from 'axios';

const testJSON = "http://178.62.120.236/";
const liveJSON = "https://onomatopoeia.club/wp/";

export const getData = {
  loadJSON : function (){
    return new Promise ((resolve, reject)=>{
      axios.get(liveJSON)
        .then(res => {
          resolve(res.data);
        })
    });
  }
}
