import Navigo from 'navigo';
import gsap from 'gsap';
import '/node_modules/defiant.js/dist/defiant.min.js';
import { getData } from './loadData';
import Splitting from 'splitting';

import header_html from '/src/templates/header.html';
import nav_html from '/src/templates/nav.html';
import loading_html from '/src/templates/loading.html';
import feed_html from '/src/templates/feed.html';
import info_html from '/src/templates/info.html';
import post_html from '/src/templates/post.html';

import displayLoading from './loading';
import displayFeed from './feed';
import displayPost from './post';
import displayInfo from './info';

let app = document.getElementById('app');
let header = document.getElementsByTagName('header')[0];
let logo = document.getElementsByClassName('logo');
let main = document.getElementsByTagName('main')[0];
let nav = document.getElementsByTagName('nav')[0];
let mainElements = [];
let backButton = document.getElementById('backToIssue');

var btnFeed = "";
var btnInfo = "";
var btnEnter = "";

var root = "/";
var useHash = false;
var hash = '#!';
var router = new Navigo(root, useHash, hash);

var snapshot;
var data = '';
var latestIssueId = 0;
var currentIssueId = '';
var projectId = '';
var orgnisedData = {};
var issueBrowsed = 0;

var delayIntro = 0.75;
var appStart = true;
var vol = 1;

// Load JSON
getData.loadJSON()
  .then(onoData => {
    data = onoData;
    setTimeout(function(){
      organiseData();
      renderMainTemplates();
      renderNavigation();
      renderBackLink();
      setRouting();
    }, 100);
  });


// Organise JSON
function organiseData(){
  snapshot = defiant.getSnapshot(data);
}

// Render Navigation
function renderNavigation(){
  document.getElementById('issuesSelecta').insertAdjacentHTML("beforeend", "<option disabled>Choose issue</option>");
  for (var i = 0; i < data.issues.length; i++) {
    var issueTitle = data.issues[i].name;
    var option = `<option value="${i}">${issueTitle}</option>`;
    document.getElementById('issuesSelecta').insertAdjacentHTML("beforeend", option);
  }
  document.getElementById("issuesSelecta").addEventListener("change", selectTheIssue);
}

// Change issue
function selectTheIssue(){
  var value = data.issues.length - document.getElementById('issuesSelecta').value;
  router.navigate('issue/'+value);
  issueBrowsed = value;
  //console.log(data.issues.length - value);
}

// Back link
function renderBackLink(){
  let backButton = document.getElementById('backToIssue');

  backButton.addEventListener("click", trackIssue);

  function trackIssue(){
    var to = "issue/"+issueBrowsed;
    router.navigate(to);
  };
}

// Render Main
function renderMainTemplates(){

  loadHTML(header, header_html);
  loadHTML(main, loading_html);
  loadHTML(main, feed_html);
  loadHTML(main, post_html);
  loadHTML(main, info_html);
  loadHTML(nav, nav_html);

  let sections = {};
  sections.app = document.getElementById('app');
  sections.loading = document.getElementById('loading');
  sections.feed = document.getElementById('feed');
  sections.post = document.getElementById('post');
  sections.info = document.getElementById('info');
  mainElements.push(sections);

  btnFeed = document.getElementById('btnFeed');
  btnInfo = document.getElementById('btnInfo');

  issueBrowsed = data.issues.length;

  if(appStart){
    sections.feed.classList.add('hideFeed');
    displayLoading.displayLoading(mainElements[0]);
    var letters_ono = mainElements[0].loading.getElementsByClassName('cage_2');
    mainElements[0].loading.style.cursor = "progress";

    for (var i = 0; i < letters_ono.length; i++) {
      var target = letters_ono[i].getElementsByTagName('h3');
      gsap.to(target, {y:"100%", duration:0.4, ease:"power2.easeInOut", delay: 2 + i*0.2});
      if(i == (letters_ono.length-1)){
        gsap.to(target, {y:"100%", duration:0.4, ease:"power2.easeInOut", delay: 2 + i*0.2, onComplete:animateLoadingOut});
      }
    }
  }
}

function loadHTML(target, page) {
  target.insertAdjacentHTML('beforeend', page);
  router.updatePageLinks();
}

// Set routing
function setRouting(){
  router
    .on({
      'issue/:id': function (params) {
        currentIssueId = params.id;
        var valueIssueId = data.issues.length - currentIssueId;
        var postsData = defiant.search(snapshot, '//posts[issue_id='+currentIssueId+']');
        displayFeed.displayFeed(mainElements[0], postsData, currentIssueId, data.issues[valueIssueId]);
        document.getElementById("issuesSelecta").selectedIndex = valueIssueId+1;
        themeLight();
        hideBackToFeed();
        inFeed();
        rotateLogo();
      },
      'collab/:id': function (params) {
        projectId = (params.id)-1;
        displayPost.displayPost(mainElements[0], data.posts, projectId);
        issueBrowsed = data.posts[projectId].issue_id;
        if (displayPost.fromOtherPages) appStart = false;
        themeDark();
        revealBackToFeed();
        rotateLogo();
      },
      'info': function () {
        displayInfo.displayInfo(mainElements[0], data.info);
        if (displayInfo.fromOtherPages) appStart = false;
        document.getElementById("issuesSelecta").selectedIndex = 0;
        themeLight();
        hideBackToFeed();
        inInfo();
        rotateLogo();
      },
      '*': function () {
        var initialData = defiant.search(snapshot, '//posts[issue_id='+data.issues.length+']');
        displayFeed.displayFeed(mainElements[0], initialData, currentIssueId, data.issues[latestIssueId]);
        document.getElementById("issuesSelecta").selectedIndex = 1;
        themeLight();
        hideBackToFeed();
        inFeed();
        rotateLogo();
      }
    })
    .resolve();

  // Set the 404 route
  router.notFound(function (query) {
    main.innerHTML = '<h3>Couldn\'t find the page you\'re looking for...</h3>';
  });
  router.resolve();
}

function rotateLogo(){
  gsap.fromTo(logo, {rotation:0}, {rotation:360, duration:1, ease:"power2.out"});
}

function inFeed(){
  btnFeed.classList.remove('deselectedSelect');
  btnInfo.classList.remove('selected');

  if (appStart) {
    header.style.opacity = 0;
  }else {
    mainElements[0].feed.classList.remove('hideFeed');
    displayFeed.revealPostsGrid();
    displayFeed.revealTitle();
  }
}

function inInfo(){
  btnFeed.classList.add('deselectedSelect');
  btnInfo.classList.add('selected');
}

function themeDark(){
  app.classList.add('reverseColor');
}

function themeLight(){
  app.classList.remove('reverseColor');
  mainElements[0].post.innerHTML = "";
}

function revealBackToFeed(){
  app.classList.add('revealBackToFeed');
}

function hideBackToFeed(){
  app.classList.remove('revealBackToFeed');
}

function animateLoadingOut(){
  mainElements[0].feed.classList.remove('hideFeed');
  var containerLoading = mainElements[0].loading;
  var loading_logo = document.getElementsByClassName('loading_logo');
  var loadingCopy = document.getElementsByClassName('loading_copy')[0];

  //Animate Out Loading

  gsap.to(loading_logo, {scale:0.7, opacity:0, duration:.3, ease:"power2.easeInOut", delay: 2});
  gsap.to(loadingCopy, {y:"-15px", opacity:0, duration:.5, ease:"power2.easeInOut", delay: 2});
  gsap.to(containerLoading, {opacity:0, duration:.4, ease:"none", delay: 2, onComplete:removeLoading});

  if (appStart) {
    appStart = false;
    gsap.fromTo(nav,{y:"30px", opacity:0}, {y:"0px", opacity:1, duration:.4, ease:"power3.easeInOut", delay:3});
  }
}

function resetFeed(){
  app.classList.remove('reverseLogoColor');
  document.body.style.overflowY = "scroll";
}

function removeLoading(){
  var target = mainElements[0].loading;
  document.body.style.overflowY = "scroll";
  target.style.display = "none";
  target.innerHTML = "";
  displayFeed.revealPostsGrid();
  displayFeed.revealTitle();
  gsap.to(header, {opacity:1, duration:.5, ease:"none", delay: .5});
}

export default router;
