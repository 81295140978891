import Navigo from 'navigo';
import router from './onomatopoeia'
import gsap from 'gsap';

const displayPost = (mainElements, dataFromJSON, postId) => {
  document.body.style.overflowY = "scroll";
  mainElements.loading.innerHTML = "";
  mainElements.loading.style.display = "none";
  mainElements.feed.style.display = "none";
  mainElements.post.style.display = "flex";
  mainElements.info.style.display = "none";

  window.scrollTo(0, 0);
  popolatePost(mainElements.post, dataFromJSON, postId);
  //return appStart;
}
const fromOtherPages = true;
export default {displayPost, fromOtherPages};

function popolatePost (container, postData, currentId){

  container.innerHTML = "";

  var normalId = currentId+1;
  var nextPostId = 0;
  var prevPostId = 0;

  if(currentId < (postData.length-1)){
    nextPostId = currentId+1;
  }else {
    nextPostId = 0;
  }

  if(currentId == 0){
    var prevPostId = (postData.length-1);
  }else {
    var prevPostId = currentId-1;
  }

  if(postData[currentId].visuals_name == "" || postData[currentId].visuals_name == null){
    var visuals_name = "Missing";
  }else {
    var visuals_name = postData[currentId].visuals_name;
  }

  if(postData[currentId].visuals_url == "" || postData[currentId].visuals_url == null){
    var visuals_url = "Missing";
  }else {
    var visuals_url = postData[currentId].visuals_url;
  }

  if(postData[currentId].sounds_name == "" || postData[currentId].sounds_name == null){
    var sounds_name = "Missing";
  }else {
    var sounds_name = postData[currentId].sounds_name;
  }

  if(postData[currentId].sounds_url == "" || postData[currentId].sounds_url == null){
    var sounds_url = "Missing";
  }else {
    var sounds_url = postData[currentId].sounds_url;
  }

  if(postData[currentId].title == "" || postData[currentId].title == null){
    var title = "Missing";
  }else {
    var title = postData[currentId].title;
  }

  if(postData[currentId].content == "" || postData[currentId].content == null){
    var content = "";
  }else {
    var content = postData[currentId].content;
  }

  if(postData[currentId].video_url == "" || postData[currentId].video_url == null){
    var video_url = "Missing";
  }else {
    var video_url = postData[currentId].video_url;
  }

  if(!postData[currentId].placeholder){
    var video_placeholder = "Missing";
  }else {
    var video_placeholder = postData[currentId].placeholder.medium;
  }

  if(!postData[nextPostId].placeholder){
    var thumb_next = "https://onomatopoeia.club/src/media/placeholder.png";
  }else {
    var thumb_next = postData[nextPostId].placeholder.medium;
  }

  if(!postData[prevPostId].placeholder){
    var thumb_prev = "https://onomatopoeia.club/src/media/placeholder.png";
  }else {
    var thumb_prev = postData[prevPostId].placeholder.medium;
  }

  var post_status = postData[currentId].status;
  var post_date = postData[currentId].date;
  var post_html = ``;
  var halfLeft = "";
  var halfRight = "";

  if(post_status != "publish"){
    post_html = `
      <div class="post_details_media">
        <img src="${video_placeholder}">
      </div>
      <div class="post_details">
        <div class="post_details_head">
          <div class="post_details_header">
            <div>
              <h4>Visuals</h4>
              <a href="${visuals_url}" target="_blank"><h3>${visuals_name}</h3></a>
            </div>
            <div>
              <h4>Sounds</h4>
              <a href="${sounds_url}" target="_blank"><h3>${sounds_name}</h3></a>
            </div>
          </div>
          <div class="post_details_title"><h1>Coming ${post_date}</h1></div>
        </div>
        <div class="posts_navigation">
          <a href="../collab/${nextPostId+1}" data-navigo>
            <div class="posts_navigation_next">
              <div class="posts_navigation_media"><img src="${thumb_next}"></div>
              <div class="posts_navigation_icon">
                <div>
                  <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0862 0.96552C19.5149 0.365624 18.5654 0.342467 17.9655 0.913796C17.3656 1.48512 17.3425 2.43459 17.9138 3.03449L25.5 11H3.5C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14H25.5L17.9138 21.9655C17.3425 22.5654 17.3656 23.5149 17.9655 24.0862C18.5654 24.6575 19.5149 24.6344 20.0862 24.0345L30.0862 13.5345C30.6379 12.9552 30.6379 12.0448 30.0862 11.4655L20.0862 0.96552Z"/>
                  </svg>
                </div>
              </div>
            </div>
          </a>
          <a href="../collab/${prevPostId+1}" data-navigo>
            <div class="posts_navigation_prev">
              <div class="posts_navigation_media"><img src="${thumb_prev}"></div>
              <div class="posts_navigation_icon reverse">
                <div>
                  <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0862 0.96552C19.5149 0.365624 18.5654 0.342467 17.9655 0.913796C17.3656 1.48512 17.3425 2.43459 17.9138 3.03449L25.5 11H3.5C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14H25.5L17.9138 21.9655C17.3425 22.5654 17.3656 23.5149 17.9655 24.0862C18.5654 24.6575 19.5149 24.6344 20.0862 24.0345L30.0862 13.5345C30.6379 12.9552 30.6379 12.0448 30.0862 11.4655L20.0862 0.96552Z"/>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    `;
  } else {
    post_html = `
      <div class="post_details_media">
        <div class="externalVideoWrapper">
          <div class="videoWrapper">
            <video loop playsinline poster="${video_placeholder}" width="1000" height="1000">
              <source src="${video_url}" type="video/mp4">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
        <div class="videoPlay">Play</div>
      </div>
      <div class="post_details">
        <div class="post_details_head">
          <div class="post_details_header">
            <div>
              <h4>Visuals</h4>
              <a href="${visuals_url}" target="_blank" class="underlinedLinkWhite"><h3>${visuals_name}</h3></a>
            </div>
            <div>
              <h4>Sounds</h4>
              <a href="${sounds_url}" target="_blank" class="underlinedLinkWhite"><h3>${sounds_name}</h3></a>
            </div>
          </div>
          <div class="post_details_title"><h1>${title}</h1></div>
        </div>
        <div class="post_details_description">${content}</div>
        <div class="posts_navigation">
          <a href="../collab/${nextPostId+1}" data-navigo>
            <div class="posts_navigation_next">
              <div class="posts_navigation_media"><img src="${thumb_next}"></div>
              <div class="posts_navigation_icon">
                <div>
                  <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0862 0.96552C19.5149 0.365624 18.5654 0.342467 17.9655 0.913796C17.3656 1.48512 17.3425 2.43459 17.9138 3.03449L25.5 11H3.5C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14H25.5L17.9138 21.9655C17.3425 22.5654 17.3656 23.5149 17.9655 24.0862C18.5654 24.6575 19.5149 24.6344 20.0862 24.0345L30.0862 13.5345C30.6379 12.9552 30.6379 12.0448 30.0862 11.4655L20.0862 0.96552Z"/>
                  </svg>
                </div>
              </div>
            </div>
          </a>
          <a href="../collab/${prevPostId+1}" data-navigo>
            <div class="posts_navigation_prev">
              <div class="posts_navigation_media"><img src="${thumb_prev}"></div>
              <div class="posts_navigation_icon reverse">
                <div>
                  <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0862 0.96552C19.5149 0.365624 18.5654 0.342467 17.9655 0.913796C17.3656 1.48512 17.3425 2.43459 17.9138 3.03449L25.5 11H3.5C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14H25.5L17.9138 21.9655C17.3425 22.5654 17.3656 23.5149 17.9655 24.0862C18.5654 24.6575 19.5149 24.6344 20.0862 24.0345L30.0862 13.5345C30.6379 12.9552 30.6379 12.0448 30.0862 11.4655L20.0862 0.96552Z"/>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    `;
  }
  container.insertAdjacentHTML('beforeend', post_html);
  halfLeft = document.getElementsByClassName('post_details_media')[0];
  halfRight = document.getElementsByClassName('post_details')[0];

  gsap.fromTo(halfLeft, {y:20, opacity: 0}, {y:0, opacity: 1, duration:1, ease:"power2.out", delay: 0.6});
  gsap.fromTo(halfRight, {y:20, opacity: 0}, {y:0, opacity: 1, duration:1, ease:"power2.out", delay: 0.9});

  var video = halfLeft.querySelector('video');
  var promise = video.play();
  var playButton = halfLeft.querySelector('.videoPlay');

  video.addEventListener("click", function() {
    if (video.paused == true) {
      video.play();
      gsap.to(playButton, {opacity: 0, duration:.1, ease:"none", onComplete:function(){playButton.style.display = "none";}})
    } else {
      video.pause();
      playButton.style.display = "block";
      playButton.style.opacity = 0;
      gsap.to(playButton, {opacity: 1, duration:.1, ease:"none"});
    }
  });

  video.addEventListener('canplay', (event) => {
    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!
      }).catch(error => {
        // Show something in the UI that the video is muted
        playButton.style.display = "block";
      });
    }

  });

  router.updatePageLinks();
}
