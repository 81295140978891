import Navigo from 'navigo';
import router from './onomatopoeia'
import gsap from 'gsap';
import Splitting from 'splitting';

const displayFeed = (mainElements, dataFromJSON, issueId, issueMeta, isHome) => {
  mainElements.feed.style.display = "inline-block";
  mainElements.post.style.display = "none";
  mainElements.info.style.display = "none";

  window.scrollTo(0, 0);

  generateHeader(dataFromJSON, issueMeta);
  generateGrid(dataFromJSON, mainElements.feed);
}

export default {displayFeed, revealPostsGrid, revealTitle};

var posts = "";

function generateHeader(postsData, issueData){
  var divTitle = document.getElementById('issuesHeader_title');
  var divArtittList = document.querySelectorAll('.ticketList');
  var divList1 = document.getElementById('issuesHeader_artistsList');
  var divList2 = document.getElementById('issuesHeader_artistsList2');
  var titleIssue = `<h1 data-splitting>${issueData.name}</h1>`;
  var artistList = [];

  divTitle.innerHTML = '';
  divTitle.innerHTML = titleIssue;
  Splitting();
  var letters = divTitle.querySelectorAll('.char');

  divList1.innerHTML = '';
  divList2.innerHTML = '';
  divList1.style.animation = null;
  divList2.style.animation = null;

  for (var i = 0; i < letters.length; i++) {
    var target = letters[i];
    target.style.display = "inline-block";
    target.style.opacity = 0;
  }

  for (var i = 0; i < postsData.length; i++) {
    var post_order = postsData[i].order;
    var sounds_name = "<a href='collab/"+(post_order)+"' class='underlinedLink' data-navigo>"+postsData[i].sounds_name+"</a>";
    var visuals_name = "<a href='collab/"+(post_order)+"' class='underlinedLink' data-navigo>"+postsData[i].visuals_name+"</a>";
    divList1.insertAdjacentHTML("afterbegin", visuals_name+" + "+sounds_name+"&nbsp;&nbsp;&bull;&nbsp;&nbsp;");
    divList2.insertAdjacentHTML("afterbegin", visuals_name+" + "+sounds_name+"&nbsp;&nbsp;&bull;&nbsp;&nbsp;");
  }

  setTimeout(function(){
    divList1.style.animation = "marquee 30s linear infinite";
    divList2.style.animation = "marquee 30s linear infinite";
  }, 100);
}

function generateGrid(postsData, container){
  var grid = container.getElementsByClassName('grid')[0];
  grid.innerHTML = "";

  for (var i = 0; i < postsData.length; i++) {
    var post_order = postsData[i].order;
    var visuals_name = postsData[i].visuals_name;
    var sounds_name = postsData[i].sounds_name;
    var post_status = postsData[i].status;
    var post_date = postsData[i].date;
    var postCard = ``;
    var post_thumb_m = "";
    var post_thumb_s = "";

    if(!postsData[i].placeholder){
      post_thumb_m = "https://onomatopoeia.club/src/media/placeholder.png";
      post_thumb_s = "https://onomatopoeia.club/src/media/placeholder.png";
    }else {
      post_thumb_m = postsData[i].placeholder.medium;
      post_thumb_s = postsData[i].placeholder.thumbnail;
    }

    if(post_status != "publish"){
      postCard = `
        <a style="pointer-events: none">
          <div class="postCard" style="pointer-events: none">
            <div class="postCard_thumb">
              <img src="${post_thumb_s}" srcset="${post_thumb_s} 480w, ${post_thumb_m} 800w" sizes="(max-width: 600px) 480px,
            800px" alt="${visuals_name} + ${sounds_name}">
            </div>
            <div class="postCard_details">
              <h3>${visuals_name} + ${sounds_name}</h3>
              <p>Coming ${post_date}</p>
            </div>
          </div>
        </a>
      `;
    }else {
      postCard = `
        <a href="collab/${post_order}" data-navigo>
          <div class="postCard">
            <div class="postCard_thumb">
              <img src="${post_thumb_s}" srcset="${post_thumb_s} 480w, ${post_thumb_m} 800w" sizes="(max-width: 600px) 480px,
            800px" alt="${visuals_name} + ${sounds_name}">
            </div>
            <div class="postCard_details">
              <h3>${visuals_name} + ${sounds_name}</h3>
            </div>
          </div>
        </a>
      `;
    }
    grid.insertAdjacentHTML('beforeend', postCard);
    document.querySelectorAll('.postCard')[i].style.opacity = 0;
    document.querySelectorAll('.postCard')[i].style.transform = "translateY(20px)";
  }
  container.classList.add('active_feed');
  router.updatePageLinks();
}

function revealPostsGrid(){
  var container = document.getElementById('feed');
  for (var i = 0; i < container.querySelectorAll('.postCard').length; i++) {
    var target = container.querySelectorAll('.postCard')[i];
    target.style.pointerEvents = "auto";
    gsap.to(target, {y:0, opacity: 1, duration:1, ease:"power2.out", delay:0.3+i*0.08});
    if(i==(container.querySelectorAll('.postCard').length-1)){
      gsap.to(target, {y:0, opacity: 1, duration:1, ease:"power2.out", delay:0.3+i*0.08, onCompleteParams:[container]});
    }
  }
}

function revealTitle(){
  var divTitle = document.getElementById('issuesHeader_title');
  var letters = divTitle.querySelectorAll('.char');
  var divArtittList = document.querySelectorAll('.ticketList');

  setTimeout(function(){
    for (var i = 0; i < letters.length; i++) {
      var target = letters[i];
      gsap.fromTo(target, {opacity: 0, transform:"translateY(20px)"}, {opacity: 1, transform:"translateY(0px)", duration:0.8, ease:"power2.out", delay: i*0.1});
    }
  }, 100);

  gsap.fromTo(divArtittList, {opacity:0,  y:20}, {opacity:1,  y:0, duration:0.8, ease:"power2.out", delay:0.9});
}
